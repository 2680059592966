// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//

$primary:        #273069!default;
$dark :          #131b4b!default;

body {

    background-color: #ebebea!important;
}