
// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;



// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg

/* epilogue-200 - latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/epilogue-v13-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/epilogue-v13-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/epilogue-v13-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/epilogue-v13-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/epilogue-v13-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/epilogue-v13-latin-200.svg#Epilogue') format('svg'); /* Legacy iOS */
}
/* epilogue-regular - latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/epilogue-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/epilogue-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/epilogue-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/epilogue-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/epilogue-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/epilogue-v13-latin-regular.svg#Epilogue') format('svg'); /* Legacy iOS */
}
/* epilogue-700 - latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/epilogue-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/epilogue-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/epilogue-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/epilogue-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/epilogue-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/epilogue-v13-latin-700.svg#Epilogue') format('svg'); /* Legacy iOS */
}

$primary:                                                   #131b4b!default;

$font-family-base:                                          "Epilogue", sans-serif;

// Colors
$body-color:                                                #2B2A2A!important;

// Avatar sizes

$avatar-width-xxl :                                         10rem;
$avatar-height-xxl:                                         10rem;


h2 { color: $primary}

.text-light {

  font-style: normal!important;
  font-weight: 200!important;
}

.accordion-button {
    background: none!important;
}

