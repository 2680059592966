// Front Default Color Variables
// =========================================================

       /*
            *
            * ==========================================
            * CUSTOM UTIL CLASSES
            * ==========================================
            *
            */
            
            .video-header {
                position: relative;
                background-color: black;
                // height: 75vh;
                min-height: 25rem;
                width: 100%;
                // overflow: hidden;
            }

            .video-header  video {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: 0;
                -ms-transform: translateX(-50%) translateY(-50%);
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }

            .video-header  .container {
                position: relative;
                z-index: 2;
            }

            .video-header  .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: black;
                opacity: 0.5;
                z-index: 1;
            }

            @media (pointer: coarse) and (hover: none) {
                .video-header  {
                    background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
                }
                .video-header video {
                    display: none;
                }
            }            

            .video-background-holder {
                position: relative;
                background-color: black;
                height: calc(100vh - 72px);
                min-height: 25rem;
                width: 100%;
                overflow: hidden;
            }

/*
            .video-background-holder video {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: 0;
                -ms-transform: translateX(-50%) translateY(-50%);
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }

            .video-background-content {
                position: relative;
                z-index: 2;
            }

            .video-background-overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: black;
                opacity: 0.5;
                z-index: 1;
            }
*/
