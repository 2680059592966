// Front Default Color Variables
// =========================================================

$root-path:                                                 "../";


// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;



.acf-label label {
    @extend .form-label;
    @extend .mt-2;
}

.acf-button {

    @extend .btn;
    @extend .btn-success;
    @extend .my-4;
    min-width: 250px;
}


